<template>
  <div>
    <!-- @init="onInit" -->

    <div style="width: 100%; height: 100vh; background: #000">
      <qrcode-stream @decode="onDecode" @init="onInit">
        <h1>
          <p class="error">{{ error }}</p>
        </h1>
      </qrcode-stream>
    </div>
  </div>
</template>

<script>
  import dayjs from 'dayjs';
  import API from '@/connect/config';
  import axios from 'axios';
  import { QrcodeStream, QrcodeDropZone, QrcodeCapture } from 'vue-qrcode-reader';
  // LIFF initialization
  import liff from '@line/liff';
  export default {
    components: {
      QrcodeStream,
      QrcodeDropZone,
      QrcodeCapture,
    },
    data() {
      return {
        error: '',
        items: [],
      };
    },
    async created() {
      await this.getData();
      // const found = this.items.find((element) => element.visitorNumber === '213');
      // if (found.estampStatus === 'พบ') {
      //   this.$swal({
      //     title: 'e-Stamp แล้ว',
      //     text: '',
      //     type: 'success',
      //     customClass: {
      //       confirmButton: 'btn btn-primary',
      //       cancelButton: 'btn btn-outline-danger ml-1',
      //     },
      //   }).then((result) => {
      //     if (result.isConfirmed === true) {
      //       liff.closeWindow();
      //     }
      //   });
      //   return;
      // }
      // if (found.estampStatus === 'ไม่ได้ e-stamp') {
      //   this.$swal({
      //     title: 'ไม่ได้ e-stamp',
      //     text: '',
      //     type: 'success',
      //     customClass: {
      //       confirmButton: 'btn btn-primary',
      //       cancelButton: 'btn btn-outline-danger ml-1',
      //     },
      //   }).then((result) => {
      //     if (result.isConfirmed === true) {
      //       liff.closeWindow();
      //     }
      //   });
      //   return;
      // }
      // console.log(found);
    },
    methods: {
      async getData() {
        // 'https://api.visitors.wacsvdev.com/estamp/6648731e47aa880025b38444?timeEstamp=20240705&?_page=1&_limit=100&_sort=1';
        const { userId, uId } = JSON.parse(localStorage.getItem('company_jwt_vis'));

        console.log(userId, uId);
        const date = new Date();
        const date_format = dayjs(date).format('YYYYMMDD');
        this.items = await this.$store.dispatch('getAll', `${API}estamp/${userId}?timeEstamp=${date_format}`).then(async (el) => {
          console.log(el);
          return await el;
        });
      },
      onDecode(result) {
        console.log(this.items);
        console.log(result);
        const found = this.items.find((element) => element.visitorNumber === result);
        if (found.estampStatus === 'พบ') {
          this.$swal({
            title: '<p class="text-success">e-Stamp แล้ว</p>',
            text: '',
            type: 'success',
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-danger ml-1',
            },
          }).then((result) => {
            if (result.isConfirmed === true) {
              liff.closeWindow();
            }
          });
          return;
        }
        if (found.estampStatus === 'ไม่ได้ e-stamp') {
          this.$swal({
            title: '<p class="text-danger">ไม่ได้ e-stamp</p>',
            text: '',
            type: 'success',
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-danger ml-1',
            },
          }).then((result) => {
            if (result.isConfirmed === true) {
              liff.closeWindow();
            }
          });
          return;
        }
        if (found != undefined || found.estampStatus == 'มา') {
          const { userId } = JSON.parse(localStorage.getItem('company_jwt_vis'));
          const { username } = JSON.parse(localStorage.getItem('profile_visitor'));
          const dataEstamp = {
            userId: userId,
            estampId: found.estampId,
            visitorNumber: found.visitorNumber,
            useForm: found.useForm,
            houseNumber: found.houseNumber,
            estampStatus: 'พบ',
            estampBy: username,
            estampSucceed: 'true',
          };
          axios
            .put(`${API}estamp`, dataEstamp)
            .then((el) => {
              console.log(el);
              this.$swal({
                title: '<p class="text-success">Stamp สำเร็จ</p>',
                text: '',
                type: 'success',
                customClass: {
                  confirmButton: 'btn btn-primary',
                  cancelButton: 'btn btn-outline-danger ml-1',
                },
              }).then((result) => {
                if (result.isConfirmed === true) {
                  liff.closeWindow();
                }
              });
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          if (result != null) {
            this.$swal({
              title: 'เลข VISITOR ' + result + ' ไม่มีในระบบ',
              text: '',
              type: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-outline-danger ml-1',
              },
            }).then((result) => {
              liff.closeWindow();
            });
          }
        }
      },
      async onInit(promise) {
        promise
          .then(() => {
            console.log('Successfully initialized! Ready for scanning now!');
          })
          .catch((error) => {
            if (error.name === 'NotAllowedError') {
              this.error = 'ERROR: you need to grant camera access permission';
            } else if (error.name === 'NotFoundError') {
              this.error = 'ERROR: no camera on this device';
            } else if (error.name === 'NotSupportedError') {
              this.error = 'ERROR: secure context required (HTTPS, localhost)';
            } else if (error.name === 'NotReadableError') {
              this.error = 'ERROR: is the camera already in use?';
            } else if (error.name === 'OverconstrainedError') {
              this.error = 'ERROR: installed cameras are not suitable';
            } else if (error.name === 'StreamApiNotSupportedError') {
              this.error = 'ERROR: Stream API is not supported in this browser';
            } else if (error.name === 'InsecureContextError') {
              this.error = 'ERROR: Camera access is only permitted in secure context. Use HTTPS or localhost rather than HTTP.';
            } else {
              this.error = `ERROR: Camera error (${error.name})`;
            }
          });
      },
    },
  };
</script>

<style></style>
